import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-gps-locations"
    }}>{`Configure GPS Locations`}</h1>
    <hr></hr>
    <p>{`To provide a GPS location for your mobile app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Channels section from the left side menu options. Click on the Channel where you want to add a GPS location for. `}</li>
      <li parentName="ol">{`Within the selected Channel, select the 'Location' tab.`}</li>
    </ol>
    <p><img alt="CMS GPS" src={require("./images/channel_gps.png")} />{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Provide the primary coordinates in the 'Primary GPS Co-ordinates (Lat,Lng)' box. This will be used to identify where your patrons can use the self-check functionaltiy as well as be the main address for this library location. `}</li>
      <li parentName="ol">{`Toggle 'Show Directions' if you want want the app to provide the option for for directions. `}</li>
      <li parentName="ol">{`Provide additional coordinates for other locations that your patrons can use the self-check functionality. For example, a mobile book truck. List each set of coordinates on a separate line.`}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to save the changes. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      